<template>

    <div class="leftwarp" :class="{'activeLeftMenu':activeLeftMenu}">
      <div class="content">
        <left-menu-link-comp></left-menu-link-comp>
        <div style="width: 100%;text-align: left;padding-left: 1rem;color: #fff9ff;margin: 5px 0 0 0">오늘의 경기</div>
        <left-sports-game-type :game-type="sportsConst.GAME_TYPE_ALL"></left-sports-game-type>
        <left-sports-game-type :game-type="sportsConst.GAME_TYPE_SOCCER"></left-sports-game-type>





      </div>
<!--      <div class="custom-vertical-scrollbar" ref="vertical">-->
<!--        <div class="custom-vertical-indicator"></div>-->
<!--      </div>-->
  </div>

</template>

<script>
import BScroll from "better-scroll";
import MouseWheel from '@better-scroll/mouse-wheel'
import ScrollBar from '@better-scroll/scroll-bar'
import LeftMenuLinkComp from "@/views/afront/zero/LeftMenuLinkComp.vue";
import LeftSportsGameType from "@/views/afront/sports/gametype/LeftSportsGameType.vue";
import sportsConst from "@/common/sportsConst";
BScroll.use(MouseWheel)
BScroll.use(ScrollBar)
export default {
  name: "Left",
  components: {LeftSportsGameType, LeftMenuLinkComp},
  data() {
    return {
      activeLeftMenu:false,
      sportsConst
    }
  },
  methods: {

  },
  created() {
    this.$bus.$on('activeLeftMenu', (status)=>{
      this.activeLeftMenu = status
    })
  },
  watch: {
    game: {
      handler(newVal) {
      },
      immediate: true,
      deep: false
    },
  }
}
</script>

<style scoped>
.activeLeftMenu{
  transform: translateX(0%) !important;
  transition: 0.3s transform;
}
</style>