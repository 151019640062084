
<template>
  <div class="match_gametype">
    <div class="gametype">
      <span class="pic">
        <img src="../../../../assets/images/icon/type/1/all.svg" v-if="gameType == sportsConst.GAME_TYPE_ALL">
        <img src="../../../../assets/images/icon/type/1/football-ico.png" v-if="gameType == sportsConst.GAME_TYPE_SOCCER">
      </span>
      <span class="txt">
        <font v-if="gameType == sportsConst.GAME_TYPE_ALL">전체</font>
        <font v-if="gameType == sportsConst.GAME_TYPE_SOCCER">축구</font>
      </span>
      <span class="count">
              <span class="number" v-if="gameType == sportsConst.GAME_TYPE_ALL">{{ alltypecount }}</span>
              <span class="number" v-if="gameType == sportsConst.GAME_TYPE_SOCCER">{{ soccer }}</span>
              <span class="number" v-if="gameType == sportsConst.GAME_TYPE_BASKETBALL">{{ basketball }}</span>
              <span class="number" v-if="gameType == sportsConst.GAME_TYPE_BASEBALL">{{ baseball }}</span>
              <span class="number" v-if="gameType == sportsConst.GAME_TYPE_HOCKEY">{{ hockey }}</span>
              <span class="number" v-if="gameType == sportsConst.GAME_TYPE_VOLLEYBALL">{{ volleyball }}</span>
              <span class="number" v-if="gameType == sportsConst.GAME_TYPE_ESPORTS">{{ esports }}</span>
              <span class="number" v-if="gameType == sportsConst.GAME_TYPE_USA_FOOTBALL">{{ football }}</span>
      </span>
    </div>

  </div>
</template>

<script>
import sportsConst from "@/common/sportsConst";
import {getSportsGameCountGroupByType} from "@/network/sportsRequest";

export default {
  name: "LeftSportsGameType",
  props: {
    gameType: {
      type: Number,
      default() {
        return sportsConst.GAME_DISPLAYPOSITION_1x2;
      }
    },
    displayPosition: {
      type: Number,
      default() {
        return sportsConst.GAME_DISPLAYPOSITION_1x2;
      }
    },
  },
  data() {
    return {
      isShowLeagueTab: false,
      sportsConst,
      gameType: 0,
      soccer: 0,
      baseball: 0,
      basketball: 0,
      volleyball: 0,
      football: 0,
      hockey: 0,
      esports: 0,
      alltypecount: 0,
    }
  },
  methods:{

  },
  created() {
    this.$store.state.selectedGameType = sportsConst.GAME_TYPE_ALL
    getSportsGameCountGroupByType(sportsConst.INPLAY_NOT).then(res => {

      if (res.data.success) {
        let map = res.data.data;
        this.soccer = map[sportsConst.GAME_TYPE_SOCCER] !== undefined ? map[sportsConst.GAME_TYPE_SOCCER] : 0
        this.baseball = map[sportsConst.GAME_TYPE_BASEBALL] !== undefined ? map[sportsConst.GAME_TYPE_BASEBALL] : 0
        this.basketball = map[sportsConst.GAME_TYPE_BASKETBALL] !== undefined ? map[sportsConst.GAME_TYPE_BASKETBALL] : 0
        this.volleyball = map[sportsConst.GAME_TYPE_VOLLEYBALL] !== undefined ? map[sportsConst.GAME_TYPE_VOLLEYBALL] : 0
        this.hockey = map[sportsConst.GAME_TYPE_HOCKEY] !== undefined ? map[sportsConst.GAME_TYPE_HOCKEY] : 0
        this.football = map[sportsConst.GAME_TYPE_USA_FOOTBALL] !== undefined ? map[sportsConst.GAME_TYPE_USA_FOOTBALL] : 0
        this.esports = map[sportsConst.GAME_TYPE_ESPORTS] !== undefined ? map[sportsConst.GAME_TYPE_ESPORTS] : 0
        this.alltypecount = this.soccer + this.baseball + this.basketball + this.volleyball + this.hockey
            + this.football + this.esports ;
      }
    })
  }
}
</script>


<style scoped>

.match_gametype{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.match_gametype .gametype{
  width: 100%;
  height: 3.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(180deg, #9abf0b 0, #66a82f) !important;
  margin: .2rem;
  padding: 0 .5rem;
  border-radius: .5rem;

}
.match_gametype .gametype .pic{
  width: 10%;
}
.match_gametype .gametype .pic img{
  width: 2.3rem;
}

.match_gametype .gametype .txt{
  width: 60%;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: .5rem;
}
.match_gametype .gametype .count{
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.match_gametype .gametype .count .number{
  height: 2.2rem;
  border-radius: .5rem;
  background: #9abf0b;
  color: #474748;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>