<template>
  <div class="header">
    <div class="h1">
      <div class="headerleftmenu">
        <i class="iconfont icon-menu" @click="activeLeftMenu"></i>
      </div>
      <div class="logo">
        <img src="../../assets/images/afront/logo/logo.png" alt="">
      </div>
      <div class="topbar-line-notice">
        <i class="iconfont icon-gonggao"></i>
        <div class="mwarp">
          <vue-marquee color="#ffffff">
            <slot>입금전 계좌확인 필수 입니다!!!</slot>
          </vue-marquee>
        </div>
      </div>
      <div class="topbar-right-menu">
        <div class="top-small-btn"><i class="iconfont icon-chongzhi btn-icon-normal"></i>충전</div>
        <div class="top-small-btn"><i class="iconfont icon-tikuan btn-icon-normal"></i>환전</div>
        <div class="top-small-btn"><i class="iconfont icon-kefu btn-icon-small"></i>문의</div>
        <div class="top-small-btn"><i class="iconfont icon-message btn-icon-normal"></i>쪽지</div>
        <div class="top-small-btn"><i class="iconfont icon-rili btn-icon-small"></i>출석</div>
        <div class="top-small-btn"><i class="iconfont icon-coupon btn-icon-small"></i>쿠폰</div>
        <div class="top-small-btn"><i class="iconfont icon-tuijianren btn-icon-small"></i>추천인</div>
        <div class="top-small-btn logout"><i class="iconfont icon-logout btn-icon-small"></i>로그아웃</div>
      </div>
      <div class="headerrightmenu">
        <i class="iconfont icon-cart" @click="activeRightMenu"></i>
      </div>
    </div>
    <div class="h2">
      <router-link to="" tag="div" class="menu">스포츠</router-link>
      <router-link to="" tag="div" class="menu">스페셜</router-link>
      <router-link to="" tag="div" class="menu">인플레이</router-link>
      <router-link to="" tag="div" class="menu">미니게임</router-link>
      <router-link to="" tag="div" class="menu">카지노</router-link>
      <router-link to="" tag="div" class="menu">슬롯</router-link>
      <router-link to="" tag="div" class="menu">토큰게임</router-link>
      <router-link to="" tag="div" class="menu">공지사항</router-link>
      <router-link to="" tag="div" class="menu">이벤트</router-link>
      <router-link to="" tag="div" class="menu">고객센터</router-link>
      <router-link to="" tag="div" class="menu">베팅내역</router-link>
    </div>

    <div class="mobilemask" :class="{'activemobilemask':mobilemask}" @click="hideleftrightitems"></div>
  </div>
</template>

<script>
import '@/assets/css/afrontcommon.css'
import '@/assets/css/afrontindex.css'
import '@/assets/css/afrontmedia.css'
import '@/assets/font/iconfont/iconfont.css'
import VueMarquee from 'aring-vue-marquee'

export default {
  name: "Header",
  components: {VueMarquee},
  data() {
    return {
      mobilemask:false,
    }
  },
  methods: {
    activeLeftMenu(){
      this.mobilemask = true;
      this.$bus.$emit('activeLeftMenu', true);
    },
    activeRightMenu(){
      this.mobilemask = true;
      this.$bus.$emit('activeRightMenu', true);
    },
    hideleftrightitems(){
      this.mobilemask = false;
      this.$bus.$emit('activeLeftMenu', false);
      this.$bus.$emit('activeRightMenu', false);
    },
  },
  created() {

  },
  watch: {
    game: {
      handler(newVal) {
      },
      immediate: true,
      deep: false
    },
  }
}
</script>

<style scoped>
.iconfont {
  font-size: 4rem !important;
  color: #fff9ff;
}

.mwarp {
  width: 100%;
  border-radius: 5rem;
  border: .1rem solid #5d5d5d;
  margin: 0 .5rem;
  height: 2.8rem;
  max-width: 80rem;
}

.vue-marquee {
  padding: 0 .2rem !important;
  border-radius: 5rem !important;
  margin: 0 .2rem !important;
  font-size: 1.1rem;
}

.vue-marquee-wrapper{
  padding: 0 0 !important;
  border-radius: 5rem !important;
}
.icon-gonggao{
  font-size: 3rem!important;
  color: #989898;
}
</style>