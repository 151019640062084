<script>
export default {
  name: "LeftMenuLinkComp"
}
</script>

<template>
  <div class="left_menu_cus">
    <router-link to="" tag="span" class="m1bg"><i class="iconfont icon-chongzhi btn-icon-normal"></i>입금</router-link>
    <router-link to="" tag="span" class="m1bg lrb_border"><i class="iconfont icon-tikuan btn-icon-normal"></i>출금</router-link>
    <router-link to="" tag="span" class="m1bg"><i class="iconfont icon-kefu btn-icon-small"></i>문의</router-link>

    <router-link to="" tag="span">스포츠</router-link>
    <router-link to="" tag="span" class="lrb_border">스페셜</router-link>
    <router-link to="" tag="span">인플레이</router-link>

    <router-link to="" tag="span">미니게임</router-link>
    <router-link to="" tag="span" class="lrb_border">카지노</router-link>
    <router-link to="" tag="span" >슬롯</router-link>


    <router-link to="" tag="span">베팅내역</router-link>
    <router-link to="" tag="span" class="lrb_border">공지사항</router-link>
    <router-link to="" tag="span" >이벤트</router-link>
  </div>
</template>

<style scoped>

</style>